<template>
    <div>
        <b-row align-v="end">
            <b-col cols="6">
                <dmx-title backlink :bc-items="bcItems">{{ customer.name }}</dmx-title>
            </b-col>
            <b-col cols="6" class="d-flex align-items-end justify-content-end pb-4">
                <b-button v-if="customer.id != 0" variant="danger" v-b-modal.delete-customer-modal class="mr-1">
                    {{ $t('common_delete') }}
                </b-button>
                <b-button variant="success" @click="saveCustomer()">
                    {{ (customer.id != 0) ? $t('common_save') : $t('common_create') }}
                </b-button>
            </b-col>
        </b-row>
        <div class="dmx-card mt-3">
            <b-row no-gutters align-v="center">
                <b-col lg="2" align="left" class="pr-2">
                    {{ $t('common_name') }}:
                </b-col>
                <b-col lg="10">
                    <b-form-input v-model="customer.name"></b-form-input>
                </b-col>
            </b-row>
            <b-row no-gutters align-v="center" class="mt-3">
                <b-col sm="2" align="left" class="pr-2">
                    {{ $t('common_email') }}:
                </b-col>
                <b-col lg="10">
                    <b-form-input v-model="customer.email"></b-form-input>
                </b-col>
            </b-row>
            <b-row no-gutters align-v="center" class="mt-3">
                <b-col lg="2" align="left" class="pr-2">
                    {{ $t('common_number') }}:
                </b-col>
                <b-col lg="10">
                    <b-form-input v-model="customer.phoneNumber"></b-form-input>
                </b-col>
            </b-row>
            <b-row no-gutters align-v="center" class="mt-3">
                <b-col lg="2" align="left" class="pr-2">
                    {{ $t('common_organisation_number') }}:
                </b-col>
                <b-col lg="10">
                    <b-form-input v-model="customer.organisationNumber"></b-form-input>
                </b-col>
            </b-row>
            <b-row no-gutters align-v="center" class="mt-3">
                <b-col lg="2" align="left" class="pr-2">
                    {{ $t('common_gate_installer') }}:
                </b-col>
                <b-col lg="10">
                    <b-form-checkbox v-model="customer.isGateInstaller"></b-form-checkbox>
                </b-col>
            </b-row>
            <hr />
            <div v-if="customer.id != 0" class="d-flex justify-content-end align-items-center small">
                <span class="mr-3">Id: {{ customer.id }}</span>
                <span>Created/Modified: {{ utcFormatted(customer.updatedOnUtc) }}</span>
            </div>
        </div>

        <b-modal size="md" id="delete-customer-modal" title="Delete customer" @ok="deleteGate()">
            <p>
                You are about to delete this customer (<b>{{ customer.name }}</b>).<br />
                <b>This operation cannot be undone!</b>
            </p>

            <template #modal-footer="{ hide, ok }">
                <b-button variant="primary" @click="hide()">
                    {{ $t('common_cancel') }}
                </b-button>
                <b-button variant="danger" @click="ok()">
                    {{ $t('common_delete') }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<style scoped>
    
</style>

<script>
    import customerService from '@/services/customerService';
    import router from '@/router';

    export default {
        name: 'customerEdit',
        props: ["customerId"],
        data: () => ({
            customer: {
                id: 0,
                name: 'New Customer',
                email: '',
                phoneNumber: '',
                organisationNumber: '',
                isGateInstaller: false,
            },
        }),
        methods: {
            deleteCustomer() {
                customerService.deleteCustomer(this.customer.id)
                    .then(data => {
                        router.go(-1)
                    });
            },
            saveCustomer() {
                if (this.customerId > 0) {
                    customerService.updateCustomer(this.customer)
                        .then(data => {
                            router.go(-1)
                        });
                } else {
                    customerService.createCustomer(this.customer)
                        .then(data => {
                            router.go(-1)
                        });
                }
            },
        },
        computed: {
            bcItems() {
                return [
                    {
                        text: 'customers',
                        href: '/customer/customerList',
                    },
                    {
                        text: this.customer.name,
                        href: '/customer/editCustomer/' + this.customer.id,
                        active: true,
                    }
                ]
            },
        },
        mounted() {
            if (this.customerId > 0) {
                customerService.getCustomer(this.customerId)
                    .then(data => {
                        this.customer = data;
                    });
            }
        },
    }
</script>